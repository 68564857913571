<template>
  <CModal
    size="xl"
    :title="title"
    :show="show"
    :closeOnBackdrop="true"
    :centered="true"
    @update:show="onClose"
  >
    <div v-if="product">
      <CRow class="align-items-center mb-3">
        <CCol sm>
          <strong class="text-gray-700"
            >{{ productCategoryParent(product) }} /
            {{ productCategory(product) }}</strong
          >
          <div class="ui-type-display-lg">{{ productName(product, lang) }}</div>
        </CCol>
      </CRow>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol>
              <CRow>
                <CCol :lg="{ size: '6', order: '2' }" class="mb-3">
                  <div class="ui-type-display-sm mb-3">
                    {{ productName(product, lang) }}
                  </div>

                  <CSelect
                    v-if="productHasManyVariants(variants)"
                    :label="$t('producer.products.form.preview.variantLabel')"
                    addInputClasses="d-block w-auto"
                    :value="variantIdSelected"
                    @update:value="onVariantChange"
                    :options="variantsListOptions(variants, lang)"
                    custom
                  />
                  <p v-else>{{ pruductVariatName(currentVariant, lang) }}</p>

                  <div
                    class="ui-type-display-md font-weight-bold"
                    v-if="currentVariant"
                  >
                    {{ pruductVariatPrice(currentVariant) }}
                    <sup>{{ currencySymbol }}</sup>
                    <span
                      class="ml-2 text-warning"
                      v-if="needPriceCorrection(product, currentVariant)"
                    >
                      <font-awesome-icon icon="balance-scale" />
                    </span>
                  </div>

                  <hr />

                  <table class="w-100">
                    <tr v-if="productHasDietInfo(product)">
                      <th>{{ $t("producer.products.form.preview.diet") }}</th>
                      <td>{{ productDietInfo(product, $i18n) }}</td>
                    </tr>
                    <tr v-if="productHasCertifications(product)">
                      <th>
                        {{ $t("producer.products.form.preview.certification") }}
                      </th>
                      <td>{{ productCertifications(product) }}</td>
                    </tr>
                    <tr v-if="productVariantHasOrigin(currentVariant)">
                      <th>{{ $t("producer.products.form.preview.origin") }}</th>
                      <td>{{ productVariantOrigin(currentVariant) }}</td>
                    </tr>
                    <tr
                      v-if="productVariantHasGuaranteedDuration(currentVariant)"
                    >
                      <th>
                        {{ $t("producer.products.form.preview.warranty") }}
                      </th>
                      <td>
                        {{
                          productVariantGuaranteedDuration(
                            currentVariant,
                            $i18n
                          )
                        }}
                      </td>
                    </tr>
                  </table>
                </CCol>

                <CCol lg="5" class="mb-3">
                  <flickity
                    ref="mainFlickity"
                    :options="flickityProduct"
                    class="flickity-product mb-2"
                  >
                    <figure
                      class="flickity-figure"
                      v-for="image in images"
                      :key="`c_${image.key}`"
                    >
                      <img
                        class="flickity-img"
                        :src="image.cropped"
                        width="900"
                        height="600"
                        alt=""
                      />
                    </figure>
                  </flickity>
                  <flickity
                    ref="navFlickity"
                    :options="flickityProductAsNavFor"
                    class="flickity-product-asnavfor"
                  >
                    <figure
                      class="flickity-figure"
                      v-for="image in images"
                      :key="`t_${image.key}`"
                    >
                      <img
                        class="flickity-img"
                        :src="image.thumb"
                        width="68"
                        height="68"
                        alt=""
                      />
                    </figure>
                  </flickity>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <div
                    class="font-weight-bold mb-2"
                    v-if="productDescription(product, lang)"
                  >
                    Descrizione
                  </div>
                  <div
                    class="ui-type-body"
                    v-html="productDescription(product, lang)"
                  ></div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>

    <template #footer>
      <CButton color="primary" variant="outline" @click="onClose">{{
        $t("producer.products.form.preview.closeBtn")
      }}</CButton>
    </template>
  </CModal>
</template>

<script>
import {
  formatPrice,
  galleryImage,
  getTranslation,
} from "../../../../helpers/common";
import get from "lodash/get";
import {
  getCategoryOptions,
  getCertificationsOptions,
  getParentCategoryOptions,
  getProductionTypeOptions,
} from "../../../../helpers/options";
import { mapGetters } from "vuex";
import productMixin from "../../../../mixins/product";

export default {
  name: "ProductFormPreviewModal",

  mixins: [productMixin],

  props: {
    product: { type: Object, required: true },
    variants: { type: Array, required: true },
    lang: { type: String, required: true },
    show: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },

  data() {
    return {
      flickityProduct: {
        pageDots: false,
        prevNextButtons: false,
        imagesLoaded: true,
      },
      flickityProductAsNavFor: {
        asNavFor: ".flickity-product",
        cellAlign: "left",
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        imagesLoaded: true,
      },

      variantIdSelected: null,
      certifications: [],
      categories: [],
      categoriesParent: [],
      productionTypes: [],
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    currentVariant() {
      if (this.product !== null && this.variantIdSelected !== null) {
        return this.variants.find((v) => v.id === this.variantIdSelected);
      }
      return undefined;
    },

    images() {
      const images = [];
      if (this.product !== null) {
        const { id, image, image_label } = this.product;
        if (image) {
          images.push(galleryImage(image, id, "product"));
        }
        if (image_label) {
          images.push(galleryImage(image_label, id, "product"));
        }
        // variants
        this.variants.forEach((variant) => {
          const { id, image, image_label } = variant;
          if (image) {
            images.push(galleryImage(image, id, "variant"));
          }
          if (image_label) {
            images.push(galleryImage(image_label, id, "variant"));
          }
        });
      }
      return images;
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      const { product, variants, lang } = this;
      this.certifications = await getCertificationsOptions();
      this.categoriesParent = await getParentCategoryOptions({
        lang,
        category__macrocategory_id: product.category__macrocategory_id,
      });
      this.categories = await getCategoryOptions({
        category__macrocategory_id: product.category__macrocategory_id,
        category__parent_id: product.category__parent_id,
        lang,
      });
      this.categoriesParent = await getParentCategoryOptions({
        lang,
        category__macrocategory_id: product.category__macrocategory_id,
      });

      this.productionTypes = await getProductionTypeOptions({ lang });

      if (this.productHasOneVariant(variants)) {
        this.variantIdSelected = variants[0].id;
      }
    },

    productCategoryParent(product) {
      const category = this.categoriesParent.find(
        (c) => c.value === product.category__parent_id
      );
      return get(category, "label", "");
    },

    productCategory(product) {
      const category = this.categories.find(
        (c) => c.value === product.category_id
      );
      return get(category, "label", "");
    },

    variantsListOptions(variants, lang) {
      return [
        {
          value: null,
          label: this.$t("producer.products.form.preview.variantPlaceholder"),
        },
        ...variants.map((variant) => {
          return {
            value: variant.id,
            label: getTranslation(variant.translations, lang),
          };
        }),
      ];
    },

    productCertifications(product) {
      return this.certifications
        .filter((c) => product.certifications.includes(c.value))
        .map((c) => {
          return c.label;
        })
        .join(", ");
    },

    pruductVariatPrice(variant) {
      return variant ? formatPrice(variant.price) : "--";
    },

    productVariantHasOrigin(variant) {
      return variant && variant.production_type_id;
    },

    productVariantOrigin(variant) {
      const origin = this.productionTypes.find(
        (c) => c.value === variant.production_type_id
      );
      return get(origin, "label", "");
    },

    onVariantChange(value) {
      this.variantIdSelected = value;
      if (value !== null) {
        this.selectImageByVariantId(parseInt(value, 10));
      }
    },

    selectImageByVariantId(varantId) {
      const index = this.images.findIndex((img) => {
        return img.refId === varantId && img.type === "variant";
      });
      if (index > -1) {
        this.$refs.mainFlickity.select(index);
      }
    },

    onClose() {
      this.$emit("update:show", false);
    },
  },
};
</script>
